import {
  SubSection,
  SlashHeader,
  Alignment,
} from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import HorizontalList from 'components/generic/horizontal-list'
import { EventUpcomingCard } from 'components/events/cards/upcoming'
import { dark } from 'themes'
import Tracking, { TRACKING_EVENT } from 'scripts/tracking'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import { useEventListingsFiltersContext } from 'components/events/pages/events-home/event-listings/event-listings-filter-context'
import EventDto from 'interfaces/gql/EventDto'
import testIds from 'enums/testIds'

const PopularEventsSubSectionMarkup = ({
  data,
  title,
  SubmitEventButton,
  ...props
}: PopularEventsSubSectionMarkupProps) => {
  const enableWeightedEventListings = useFeatureSwitch(
    featureSwitches.enableWeightedEventListings
  )
  const { area } = useEventListingsFiltersContext()

  return (
    <ThemeProvider theme={dark}>
      <SubSection variant={SubSection.variant.secondary} {...props}>
        <Alignment justifyContent="space-between">
          <SlashHeader>{title}</SlashHeader>
          {SubmitEventButton && (
            <div data-testid={testIds.submitButton}>
              <SubmitEventButton />
            </div>
          )}
        </Alignment>

        <HorizontalList
          Card={EventUpcomingCard}
          mCols={4}
          items={data.map((item: EventDto, index: number) => ({
            ...item,
            withFlyer: true,
            onClick: () =>
              handleClick(
                item.id,
                item.title,
                area,
                index + 1,
                enableWeightedEventListings
              ),
          }))}
        />
      </SubSection>
    </ThemeProvider>
  )
}

const handleClick = (
  id,
  title,
  area,
  position,
  enableWeightedEventListings
) => {
  Tracking.trackMixpanel(TRACKING_EVENT.popularCarouselEventClicked, {
    'Event ID': id,
    'Event Title': title,
    'Events Region Id': area?.area?.id?.toString(),
    'Events Region Name': area?.areaName,
    'Position In Event Listing': position,
    'Ordering Used': enableWeightedEventListings ? 'New' : 'Old',
  })
}

type PopularEventsSubSectionMarkupProps = {
  data: object[]
  title?: string
  SubmitEventButton?: () => JSX.Element
}

export default PopularEventsSubSectionMarkup
