import { gql } from '@apollo/client'

const GET_POPULAR_EVENTS = gql`
  query GET_POPULAR_EVENTS(
    $filters: FilterInputDtoInput
    $pageSize: Int
    $sort: SortInputDtoInput
  ) {
    eventListings(
      filters: $filters
      pageSize: $pageSize
      page: 1
      sort: $sort
    ) {
      data {
        id
        listingDate
        event {
          id
          title
          interestedCount
          isSaved
          isInterested
          date
          contentUrl
          flyerFront
          queueItEnabled
          newEventForm
          images {
            id
            filename
            alt
            type
            crop
          }
          venue {
            id
            name
            contentUrl
            live
          }
        }
      }
    }
  }
`

export default GET_POPULAR_EVENTS
