import PropTypes from 'prop-types'
import {
  Alignment,
  Box,
  LocationIcon,
  variants,
} from '@resident-advisor/design-system'
import { dateFormat } from 'lib/dateFormat'
import InterestedCount from 'components/generic/interested-count/InterestedCount'
import Link from 'components/generic/link'
import Heading from 'components/generic/heading'
import Date from 'components/generic/date'
import ResponsiveImage from 'components/generic/responsive-image'
import MetaLink from 'components/generic/meta-link'
import MetaText from 'components/generic/meta-text'
import ASPECT_RATIO, { formatAspectRatio } from 'lib/aspectRatio'
import unescape from 'lodash/unescape'
import { eventRoute } from 'lib/routes'
import IMAGE_TYPE from 'enums/image-type'
import PLAYWRIGHT_TEST_IDS from 'enums/playwright-test-ids'
import getEventImage from 'lib/getEventImage'
import testIds from 'enums/testIds'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import SavedEventsButton, {
  EventSavedSourceType,
  SavedEventsButtonType,
} from 'components/user/pages/saved-events/saved-events-button/SavedEventsButton'

const EventUpcomingCard = ({
  title,
  date,
  venue,
  contentUrl,
  queueItEnabled,
  flyerFront,
  withFlyer,
  dateFormat: dateFormatProp,
  lazyRootRef,
  newEventForm,
  images,
  onClick,
  isSaved,
  isInterested,
  id,
  interestedCount,
}) => {
  const enableSavedEvents = useFeatureSwitch(featureSwitches.enableSavedEvents)

  return (
    <Alignment
      flexDirection="column"
      data-testid={testIds.eventUpcomingCard}
      data-pw-test-id={PLAYWRIGHT_TEST_IDS.popularEventItem}
      onClick={onClick}
    >
      {withFlyer && (
        <Box mb={3}>
          <Link
            data-pw-test-id={PLAYWRIGHT_TEST_IDS.eventImageLink}
            variant={variants.text.heading.m}
            {...eventRoute(contentUrl, queueItEnabled)}
          >
            <ResponsiveImage
              url={getEventImage(
                { flyerFront, newEventForm, images },
                IMAGE_TYPE.flyerFront
              )}
              aspect={ASPECT_RATIO['ISO-216']}
              alt={`'${title}' flyer image`}
              sizes={{ m: '25vw' }}
              lazyRootRef={lazyRootRef}
              properties={{
                fit: 'crop',
                ar: formatAspectRatio(ASPECT_RATIO['ISO-216']),
                quality: 50,
              }}
            />
          </Link>
        </Box>
      )}
      <Alignment justifyContent="space-between" flexDirection="row">
        <Date
          color="secondary"
          date={date}
          variant={variants.text.heading.s}
          format={dateFormatProp || dateFormat(date)}
        />
        {enableSavedEvents && (
          <SavedEventsButton
            type={SavedEventsButtonType.EventCardIcon}
            isSaved={isSaved}
            eventId={id}
            interestedCount={interestedCount}
            isInterested={isInterested}
            source={EventSavedSourceType.PopularSection}
          />
        )}
      </Alignment>
      <Heading data-pw-test-id={PLAYWRIGHT_TEST_IDS.eventTitle}>
        <Link
          data-pw-test-id={PLAYWRIGHT_TEST_IDS.eventTitleLink}
          variant={variants.text.heading.m}
          {...eventRoute(contentUrl, queueItEnabled)}
        >
          {unescape(title)}
        </Link>
      </Heading>
      <Box mt={2}>
        {venue?.live ? (
          <MetaLink
            Icon={LocationIcon}
            text={venue.name}
            href={venue.contentUrl}
            linkProps={{
              'data-pw-test-id': PLAYWRIGHT_TEST_IDS.eventVenueLink,
            }}
          />
        ) : (
          <MetaText
            textProps={{
              'data-pw-test-id': PLAYWRIGHT_TEST_IDS.eventVenueLink,
            }}
            Icon={LocationIcon}
            text={venue?.name}
          />
        )}
        <InterestedCount count={interestedCount} />
      </Box>
    </Alignment>
  )
}

EventUpcomingCard.propTypes = {
  title: PropTypes.string.isRequired,
  venue: PropTypes.object.isRequired,
  interestedCount: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  contentUrl: PropTypes.string.isRequired,
  flyerFront: PropTypes.string,
  queueItEnabled: PropTypes.bool,
  dateFormat: PropTypes.string,
  withFlyer: PropTypes.bool,
  lazyRootRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      filename: PropTypes.string.isRequired,
      alt: PropTypes.string,
      type: PropTypes.oneOf(Object.values(IMAGE_TYPE)),
      crop: PropTypes.object,
    })
  ),
  newEventForm: PropTypes.bool,
  onClick: PropTypes.func,
  isSaved: PropTypes.bool,
  isInterested: PropTypes.bool,
  id: PropTypes.string,
}

export default EventUpcomingCard
